import React from 'react';


class Me extends React.Component {
  render() {
    return (
    <div >
        <br/>

        <p > 
        I am an energy system expert with a broad, interdisciplinary approach to acheiving decarbonization in the building and power sectors. I am resourceful, curious, and quick to pick up new skills when they help to locate opportunities for sustainability. This journey has led me to develop skills particular in energy modeling and geospatial analysis using tools such as GEE, ArcGIS, Python, and EnergyPlus. 
        <br/><br/>
        I studied Engineering Physics at Columbia University, and this engineering perspective will always form a core part of the way I approach problems. Over the years, I have developed a particular interest in technologies such as heat pumps, solar thermal energy, green hydrogen as well as optimization problems involving more traditional renewable energy sources. I have also worked hands on with many of these technologies, having installed heat pumps, industrial refrigeration systems and PV solar systems both as an industrial operations manager and owner of my own sustainable construction company.
        <br/><br/>
        Between my academic work, hands on experience, and theoretical understanding of energy systems, I offer a wholistic understanding and broad expertise not just in the deployment of the technologies paving the way to a net zero future, but also in the path dependencies, trade-offs and techno-economic interactions that inform the policy required to guide their rollout.
        </p>

          <h3>Research Skills and Competencies</h3>
          <p>  National and Regional Energy Analysis | Techno-economic Analysis of RES and Power-to-X | Building Level Energy Modelling (eg EnergyPlus) | Multicriteria Decision Analysis (MCDA) | Geospatial Optimization Problems</p>
 
          <h3>Computer Skills</h3>
          <p>  ArcGIS | Python (Pandas, SciPy) | GEE | MS Office Suite | JavaScript (React, jQuery) | SQL| HTML & CSS | 3D Modelling (eg SketchUp, CrafWare) | ProTools</p>

          <h3>Other Interests/Skills</h3>
          <p> Welding, Light Construction, Electrical and Plumbing | Die Hard DIYer | Music Production | Bike Touring</p>

    </div>
  );
  }
}

export default Me;
